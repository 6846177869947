import "./index.css";

import { asyncWithLDProvider } from "launchdarkly-react-client-sdk";
import React from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider } from "react-router-dom";

import router from "./router";

// async function bootReactScan() {
//   const scan = await import("react-scan").then((m) => m.scan);
//   scan({ enabled: true, log: true });
// }

// if (import.meta.env.VITE_REACT_SCAN === "1") {
//   bootReactScan();
// }

async function render() {
  const root = ReactDOM.createRoot(document.getElementById("root") as Element);

  const LDProvider = await asyncWithLDProvider({
    clientSideID: import.meta.env.VITE_LD_ID,
    timeout: 1,
    options: {
      baseUrl: `${import.meta.env.VITE_LD_PROXY_URL}/ld`,
      eventsUrl: `${import.meta.env.VITE_LD_PROXY_URL}/ld-evt`,
      streamUrl: `${import.meta.env.VITE_LD_PROXY_URL}/ld-str`,
      application: {
        id: "conduit-app",
        version: import.meta.env.VITE_VERCEL_GIT_COMMIT_SHA,
      },
      diagnosticOptOut: true,
      streaming: import.meta.env.PROD,
    },
  });

  root.render(
    <React.StrictMode>
      <LDProvider>
        <RouterProvider
          router={router}
          future={{
            v7_startTransition: true,
          }}
        />
      </LDProvider>
    </React.StrictMode>,
  );
}

render();
