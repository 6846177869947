import type { Router as RemixRouter } from "@remix-run/router";
import * as Sentry from "@sentry/react";
import React from "react";
import {
  createBrowserRouter,
  createRoutesFromChildren,
  type FutureConfig,
  matchRoutes,
  redirect,
  useLocation,
  useNavigationType,
} from "react-router-dom";

import type { CrumbFunc } from "./hooks/use-title";
import BaseLayout from "./layouts/BaseLayout";
import ErrorBoundaryLayout from "./layouts/ErrorBoundaryLayout";
import RootLayout from "./layouts/RootLayout";
import { WithOrgLayout, WithUserLayout } from "./layouts/UserLayout";
import catchAndReload from "./utils/catchAndReload";
import AccountLayout from "./views/account/layout";
import DeployLayout from "./views/deploy/layout";
import ErrorPage from "./views/error";
import NotFoundPage from "./views/not-found";
import OrganizationLayout from "./views/organization-settings/layout";
import DashboardLayout from "./views/view-network/[id]/layout";
import DashboardSettingsLayout from "./views/view-network/[id]/settings/layout";

/**
 * Sentry
 */
Sentry.init({
  enabled: import.meta.env.PROD,
  dsn: import.meta.env.VITE_SENTRY_DSN,
  environment: import.meta.env.VITE_SENTRY_ENV,
  integrations: [
    Sentry.thirdPartyErrorFilterIntegration({
      filterKeys: ["xfac"],
      behaviour: "apply-tag-if-contains-third-party-frames",
    }),
    Sentry.browserProfilingIntegration(),
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
      maskAllInputs: false,
    }),
  ],
  tracesSampleRate: 0.25,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  tracePropagationTargets: [],
  profilesSampleRate: 0.25,
});

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const idCrumb: CrumbFunc = (m) => m.params.id!;
const slugCrumb: CrumbFunc = (m) => m.params.slug!;

const router: RemixRouter = sentryCreateBrowserRouter(
  [
    {
      element: <ErrorBoundaryLayout />,
      errorElement: <ErrorPage />,
      children: [
        {
          element: <RootLayout />,
          children: [
            {
              path: "/published/view/:slug",
              lazy: () =>
                import("./views/published/view/[slug]/page").catch(
                  catchAndReload,
                ),
              handle: {
                crumb: slugCrumb,
              },
            },
            {
              path: "/deploy",
              element: <DeployLayout />,
              handle: {
                crumb: "Deploy",
              },
              children: [
                {
                  index: true,
                  lazy: () =>
                    import("./views/deploy/page").catch(catchAndReload),
                },
                {
                  path: ":id",
                  lazy: () =>
                    import("./views/deploy/[id]/page").catch(catchAndReload),
                },
              ],
            },
            {
              path: "/email-signin",
              loader: () => redirect("/"),
            },
            {
              path: "/login",
              loader: () => redirect("/"),
            },
            {
              path: "/",
              lazy: () => import("./views/login/page").catch(catchAndReload),
              handle: {
                crumb: "Sign in",
              },
            },
            {
              path: "/signup",
              lazy: () => import("./views/signup/page").catch(catchAndReload),
              handle: {
                crumb: "Sign up",
              },
            },
            {
              path: "/email-signin-completion",
              lazy: () =>
                import("./views/email-signin-completion/page").catch(
                  catchAndReload,
                ),
              handle: {
                crumb: "Complete sign in",
              },
            },
            {
              element: <WithUserLayout />,
              children: [
                {
                  path: "/email-invite",
                  lazy: () =>
                    import("./views/email-invite/page").catch(catchAndReload),
                  handle: {
                    crumb: "Accept invite",
                  },
                },
                {
                  path: "/create-organization",
                  lazy: () =>
                    import("./views/create-organization/page").catch(
                      catchAndReload,
                    ),
                  handle: {
                    crumb: "Create organization",
                  },
                },
                {
                  element: <WithOrgLayout />,
                  children: [
                    {
                      path: "/install/:id",
                      handle: {
                        crumb: idCrumb,
                      },
                      children: [
                        {
                          path: ":slug",
                          handle: {
                            crumb: slugCrumb,
                          },
                          children: [
                            {
                              index: true,
                              lazy: () =>
                                import(
                                  "./views/install/[id]/[slug]/page"
                                ).catch(catchAndReload),
                              handle: {
                                crumb: "Install",
                              },
                            },
                          ],
                        },
                      ],
                    },
                    {
                      element: <BaseLayout />,
                      children: [
                        {
                          path: "/deployments",
                          lazy: () =>
                            import("./views/deployments/page").catch(
                              catchAndReload,
                            ),
                          handle: {
                            crumb: "Rollups",
                          },
                        },
                        {
                          path: "/browse-networks",
                          lazy: () =>
                            import("./views/browse-networks/page").catch(
                              catchAndReload,
                            ),
                          handle: {
                            crumb: "Browse rollups",
                          },
                        },
                        {
                          path: "/nodes",
                          handle: {
                            crumb: "Nodes",
                          },
                          children: [
                            {
                              index: true,
                              lazy: () =>
                                import("./views/nodes/page").catch(
                                  catchAndReload,
                                ),
                            },
                            {
                              path: "settings",
                              lazy: () =>
                                import("./views/nodes/settings/page").catch(
                                  catchAndReload,
                                ),
                              handle: {
                                crumb: "Settings",
                              },
                            },
                            {
                              path: ":key",
                              handle: {
                                crumb: "Key",
                              },
                              children: [
                                {
                                  index: true,
                                  lazy: () =>
                                    import(
                                      "./views/nodes/[key]/overview/page"
                                    ).catch(catchAndReload),
                                  handle: {
                                    crumb: "Overview",
                                  },
                                },
                                {
                                  path: "settings",
                                  lazy: () =>
                                    import(
                                      "./views/nodes/[key]/settings/page"
                                    ).catch(catchAndReload),
                                  handle: {
                                    crumb: "Settings",
                                  },
                                },
                              ],
                            },
                          ],
                        },
                        {
                          path: "/organization-settings",
                          element: <OrganizationLayout />,
                          handle: {
                            crumb: "Organization settings",
                          },
                          children: [
                            {
                              index: true,
                              lazy: () =>
                                import(
                                  "./views/organization-settings/page"
                                ).catch(catchAndReload),
                            },
                          ],
                        },
                        {
                          path: "/account",
                          element: <AccountLayout />,
                          handle: {
                            crumb: "Account settings",
                          },
                          children: [
                            {
                              index: true,
                              lazy: () =>
                                import("./views/account/page").catch(
                                  catchAndReload,
                                ),
                            },
                          ],
                        },
                        {
                          element: <DashboardLayout />,
                          path: "/view-network/:id/",
                          handle: {
                            crumb: idCrumb,
                          },
                          children: [
                            { index: true, loader: () => redirect("overview") },
                            {
                              path: "access",
                              loader: () => redirect("/nodes"),
                            },
                            {
                              path: "overview",
                              lazy: () =>
                                import(
                                  "./views/view-network/[id]/overview/page"
                                ).catch(catchAndReload),
                              handle: {
                                crumb: "Overview",
                              },
                            },
                            {
                              path: "metrics",
                              lazy: () =>
                                import(
                                  "./views/view-network/[id]/metrics/page"
                                ).catch(catchAndReload),
                              handle: {
                                crumb: "Metrics",
                              },
                            },
                            {
                              path: "contracts",
                              lazy: () =>
                                import(
                                  "./views/view-network/[id]/contracts/page"
                                ).catch(catchAndReload),
                              handle: {
                                crumb: "Contracts",
                              },
                            },
                            {
                              path: "integrations",
                              loader: () => redirect("../apps"),
                            },
                            {
                              path: "integrations/:slug",
                              loader: ({
                                params,
                              }: {
                                params: { slug: string };
                              }) => redirect(`../apps/${params.slug}`),
                            },
                            {
                              path: "apps",
                              handle: {
                                crumb: "Apps",
                              },
                              children: [
                                {
                                  index: true,
                                  lazy: () =>
                                    import(
                                      "./views/view-network/[id]/apps/page"
                                    ).catch(catchAndReload),
                                },
                                {
                                  path: "installed",
                                  lazy: () =>
                                    import(
                                      "./views/view-network/[id]/apps/installed/page"
                                    ).catch(catchAndReload),
                                  handle: {
                                    crumb: "Installed",
                                  },
                                },
                                {
                                  path: ":slug",
                                  handle: {
                                    crumb: slugCrumb,
                                  },
                                  children: [
                                    {
                                      index: true,
                                      lazy: () =>
                                        import(
                                          "./views/view-network/[id]/apps/[slug]/page"
                                        ).catch(catchAndReload),
                                      // handle: {
                                      //   crumb: "Details",
                                      // },
                                    },
                                  ],
                                },
                              ],
                            },
                            {
                              path: "settings/",
                              element: <DashboardSettingsLayout />,
                              handle: {
                                crumb: "Settings",
                              },
                              children: [
                                {
                                  path: "domains",
                                  loader: () => redirect("../customization"),
                                },
                                {
                                  index: true,
                                  lazy: () =>
                                    import(
                                      "./views/view-network/[id]/settings/general/page"
                                    ).catch(catchAndReload),
                                },
                                {
                                  path: "customization",
                                  lazy: () =>
                                    import(
                                      "./views/view-network/[id]/settings/customization/page"
                                    ).catch(catchAndReload),
                                  handle: {
                                    crumb: "Customization",
                                  },
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          path: "*",
          element: <NotFoundPage />,
        },
      ],
    },
  ],
  {
    future: {
      v7_fetcherPersist: true,
      v7_normalizeFormMethod: true,
      // v7_partialHydration: true,
      v7_relativeSplatPath: true,
      v7_skipActionErrorRevalidation: true,
      v7_startTransition: true,
    } as FutureConfig,
  },
);

export default router;
