import type AsyncRetry from "async-retry";
import retry from "async-retry";
import { onIdTokenChanged, type User } from "firebase/auth";
import { assertError } from "ts-extras";

import { ApiHostUrl } from "../constants/urls";
import { auth } from "../lib/firebase";
import { UserFacingError } from "./userFacingError";

export class FirebaseTokenManager {
  /**
   * Firebase Bearer JWT to pass to the Conduit API
   */
  protected idToken: string | null = null;

  /**
   * Firebase User
   */
  protected user: User | null = null;

  constructor() {
    onIdTokenChanged(auth, (user) => {
      void this.setIdToken(user);
    });
  }

  protected async setIdToken(user: User | null) {
    console.info("[FirebaseTokenManager] setIdToken");

    this.user = user;

    if (user) {
      /**
       * @note Undocumented 'accessToken' on Firebase User
       */
      if ("accessToken" in user) {
        this.idToken = user.accessToken as string;
      } else {
        this.idToken = await user.getIdToken();
      }
    }
  }

  protected async authorize() {
    if (!this.user) {
      return;
    }

    try {
      const result = await this.user.getIdTokenResult(true);

      console.info("[FirebaseTokenManager] authorize", result);

      this.idToken = result.token;
    } catch (err) {
      console.error("[FirebaseTokenManager] authorize", err);
    }
  }
}

export class UnauthorizedError extends Error {
  name = "UnauthorizedError" as const;

  constructor() {
    super("Unauthorized");
  }
}

export class APIUtilities extends FirebaseTokenManager {
  /**
   * The hostname of the API
   */
  protected apiHost = ApiHostUrl;

  constructor() {
    super();
  }

  private async fetcher<T>({
    input,
    init,
    timeout = 1_000 * 10,
  }: {
    input: URL | RequestInfo;
    init?: RequestInit | undefined;
    timeout?: number;
  }): Promise<T> {
    const execute: AsyncRetry.RetryFunction<T> = async (bail) => {
      const controller = new AbortController();

      const id = setTimeout(
        () => controller.abort(new RequestTimedOutError()),
        timeout,
      );

      init?.signal?.addEventListener("abort", () =>
        controller.abort(new RequestAbortedError()),
      );

      let response: Response;

      try {
        response = await fetch(input, {
          ...init,
          cache: "no-cache",
          credentials: "include",
          redirect: "error",
          headers: {
            ...init?.headers,
            Authorization: `Bearer ${this.idToken}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          signal: controller.signal,
        });
      } catch (_err) {
        console.error(_err);

        assertError(_err);

        const err = new UserFacingError({
          message: _err.message,
          cause: _err,
        });

        bail(err);
        return {} as T;
      }

      clearTimeout(id);

      if (response.ok) {
        let data: unknown;
        try {
          data = await response.clone().json();
        } catch {
          data = await response.text();
        }

        return data as T;
      }

      if (401 === response.status) {
        await this.authorize();

        throw new UnauthorizedError();
      }

      let message: string;
      try {
        const data: { message: string } = await response.clone().json();
        message = data.message;
      } catch {
        message = await response.text();
      }

      if (message.trim() === "") {
        message = `${response.status} ${response.statusText}`;
      }

      const err = new UserFacingError({
        message: message,
        code: response.status,
      });

      bail(err);
      return {} as T;
    };

    return retry(execute, {
      retries: 2,
      minTimeout: 2 * 1_000,
      maxTimeout: 30 * 1_000,
      onRetry(err, attempt) {
        if (err instanceof UnauthorizedError) {
          console.info(
            "[APIUtilities] received unauthorized status code, re-auth-ing and retrying",
          );
        }

        console.info("[APIUtilities] retry attempt", attempt);
      },
    });
  }

  protected get<T>(
    endpoint: `/v1/${string}` | `/public/${string}`,
    signal?: AbortSignal,
  ) {
    return this.fetcher<T>({
      input: new URL(endpoint, this.apiHost),
      init: { method: "GET", signal },
    });
  }

  protected post<T>(
    endpoint: `/v1/${string}` | `/public/${string}`,
    body: unknown,
    signal?: AbortSignal,
  ) {
    return this.fetcher<T>({
      input: new URL(endpoint, this.apiHost),
      init: { method: "POST", body: JSON.stringify(body), signal },
    });
  }
}

export class RequestTimedOutError extends Error {
  name = "RequestTimedOutError" as const;

  constructor() {
    super("Request timed out");
  }
}

export class RequestAbortedError extends Error {
  name = "RequestAbortedError" as const;

  constructor() {
    super("Request was cancelled");
  }
}

export class NetworkError extends Error {
  name = "NetworkError" as const;

  constructor() {
    super("Connection error");
  }
}
